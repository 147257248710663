<template>
  <div>
    <title>SIM INVENTORY ~ ADD ITEM OUTGOING DATA</title>
    <section class="content-header">
      <h1>
        Add Item Outgoing Data
        <br />
        <h4>Please enter the transaction data outgoing</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Items Expenditure Transaction</li>
      </ol>
    </section>
    <section class="content">
      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Items Outgoing Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Contract Number</label>
                  <input
                    type="text"
                    v-model="isidata.nomorkontrak"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Contract Number"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Outgoing Number</label>
                  <input
                    type="text"
                    v-model="isidata.outgoingno"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Outgoing Number"
                  />
                </div>

                <div class="form-group">
                  <label for="exampleInputEmail1">Out Date</label>
                  <input
                    type="date"
                    v-model="isidata.tglkeluar"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Out Type</label>
                  <select
                    @change="showjenisbc($event)"
                    class="form-control"
                    v-model="isidata.jenisout"
                    id="jenisout"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="datajenisout in jenisout"
                      :key="datajenisout.id"
                      :value="datajenisout.value"
                    >
                      {{ datajenisout.value }}
                    </option>
                  </select>
                </div>
                <div v-if="isshowjenisbc">
                  <div class="form-group">
                    <label for="exampleInputEmail1">BC Type</label>
                    <select
                      class="form-control"
                      v-model="isidata.bctype"
                      id="bctype"
                    >
                      <option disabled>--Please Select--</option>
                      <option
                        v-for="datatipebc in tipebc"
                        :key="datatipebc.id"
                        :value="datatipebc.value"
                      >
                        BC {{ datatipebc.value }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Customs Number</label>
                    <input
                      type="text"
                      v-model="isidata.nomorkepabeanan"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Customs Number"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Customs Date</label>
                    <input
                      type="date"
                      v-model="isidata.tglkepabeanan"
                      autocomplete="off"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Items Outgoing Data</h3>
            </div>
            <div class="box-body">
              <div class="form-group">
                <label for="exampleInputEmail1">Notes</label>
                <input
                  type="text"
                  v-model="isidata.catatan"
                  autocomplete="off"
                  class="form-control"
                  placeholder="Notes"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Request Material</label>
                <select2
                  :data="reqmaterial"
                  :value="valuereqmaterial"
                  @update="updatereqmaterial($event)"
                ></select2>
              </div>
              <!-- <div class="row">
                <div class="col-xs-9">
                  <label for="exampleInputEmail1">Buyer</label>
                  <input
                    type="text"
                    v-model="isidata.buyerno"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Buyer Code"
                  />
                </div>
                <div class="col-xs-3">
                  <label for="exampleInputEmail1">Action</label>
                  <br />
                  <button
                    type="button"
                    @click="getbuyer()"
                    class="btn btn-info"
                  >
                    Search
                  </button>
                </div>
              </div>
              <br />
              <div class="form-group">
                <label for="exampleInputEmail1">Buyer Name</label>
                <input
                  type="text"
                  readonly
                  v-model="isidata.namabuyer"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
              <div class="row">
                <div class="col-xs-9">
                  <label for="exampleInputEmail1">Product</label>
                  <input
                    type="text"
                    v-model="isidata.noproduk"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Product Number"
                  />
                </div>
                <div class="col-xs-3">
                  <label for="exampleInputEmail1">Action</label>
                  <br />
                  <button
                    type="button"
                    @click="getproduk()"
                    class="btn btn-info"
                  >
                    Search
                  </button>
                </div>
              </div>
              <br />
              <div class="form-group">
                <label for="exampleInputEmail1">Product Name</label>
                <input
                  type="text"
                  readonly
                  v-model="isidata.namaproduk"
                  autocomplete="off"
                  class="form-control"
                />
              </div> -->
            </div>
          </div>
        </div>
        <div v-if="loading" class="load">
          <img width="130" src="@/assets/loader.gif" alt="" />
        </div>
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-list"></i>
              <h3 class="box-title">Request Material Data</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-5">
                  Show &nbsp; :
                  <select
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by Item Code"
                    class="form-control"
                    v-model="namesearch"
                  />
                </div>
                <div class="col-xs-2">
                  <button
                    @click="searchdata()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isShowreq" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <!--<th>Request Number</th> -->
                      <!--<th>Order Number</th> -->
                      <th>Item Code</th>
                      <th>Product Number</th>
                      <th>Item Name</th>
                      <th>Request Date</th>
                      <th>Remaining Requests</th>
                      <th>Stock WH</th>
                      <!--<th>Qty</th>-->
                      <th>Qty Out</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="classdata in list_paged" :key="classdata.id">
                      <!--<td>{{ classdata.nomor_request }}</td> -->
                      <!--<td>{{ classdata.nomor_order }}</td> -->
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.nomor_produk }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>{{ classdata.tanggal_request }}</td>
                      <td>{{ classdata.sisa_request }}</td>
                      <td>{{ classdata.stok_wh }}</td>
                      <!--<td>{{ classdata.qty }}</td> -->
                      <td>
                        <input
                          :id="'inpd-' + classdata.id"
                          type="text"
                          :value="classdata.qty"
                          class="form-control"
                          :name="'nmd-' + classdata.id"
                        />
                      </td>
                      <td>
                        <button
                          @click="saveData(classdata)"
                          type="button"
                          class="btn btn-primary"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Request Number</th>
                      <th>Order Number</th>
                      <th>Item Code</th>
                      <th>Product Number</th>
                      <th>Item Name</th>
                      <th>Request Date</th>
                      <th>Balance</th>
                      <th>Stock</th>
                      <th>Qty</th>
                      <th>Qty Out</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="11" class="text-center">
                          <b style="color: red"> {{ pesan }} </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                /> -->
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <router-link to="/outgoing">
        <button class="btn btn-success">
          <i class="fa fa-angle-double-left"></i> Back
        </button>
      </router-link>
    </section>
  </div>
</template>
<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprod",
  components: {
    VPagination
  },
  data() {
    return {
      jenisout: [
        { value: "INTERNAL", id: 1 },
        { value: "EXTERNAL", id: 2 }
      ],
      tipebc: [
        { value: "41", id: 1 },
        { value: "261", id: 2 },
        { value: "30", id: 3 },
        { value: "33", id: 4 },
        { value: "25", id: 5 },
        { value: "27", id: 6 }
      ],
      loading: false,
      isshowjenisbc: false,
      isShowreq: true,
      namesearch: "",
      pesan: "",
      valuereqmaterial: "",
      list_paged: [],
      reqmaterial: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
      isidata: {
        outgoingno: "",
        orderno: "",
        tglorder: "",
        catatan: "",
        tglkeluar: "",
        buyerno: "",
        namabuyer: "",
        noproduk: "",
        namaproduk: "",
        jenisout: "",
        bctype: "",
        nomorkepabeanan: "",
        tglkepabeanan: "",
        reqmat: "",
        nomorkontrak: ""
      },
      classdata: {
        id: "",
        kode_barang: "",
        qty: "",
        nomor_order: "",
        nomor_request: ""
      }
    };
  },
  created() {
    // this.fetchreqmaterial();
    this.showawal();
    this.getnow();
    this.fetchrequestmaterial();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if(this.namesearch == '') {
        this.updatereqmaterial(this.valuereqmaterial)
      } else {
        this.searchdata()
      }
      // this.fetchrequestmaterial();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if(this.namesearch == '') {
        this.updatereqmaterial(this.valuereqmaterial)
      } else {
        this.searchdata()
      }
      // this.fetchrequestmaterial();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.isidata.tglkeluar = tahun + "-" + month + "-" + tgl;
      this.isidata.tglkepabeanan = tahun + "-" + month + "-" + tgl;
    },
    showawal() {
      this.isShowreq = false;
      this.pesan = "......";
    },
    showjenisbc(event) {
      const outtype = event.target.value;
      if (outtype == "EXTERNAL") {
        this.isshowjenisbc = true;
      } else {
        this.isshowjenisbc = false;
      }
    },
    updatereqmaterial(value) {
      this.valuereqmaterial = value;
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "request_material/getalldata_request_material_vs_qty_out_plus_stok_wh?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&nomor_request=" +
        this.valuereqmaterial;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          console.log(resp)
          if (resp.data.data.length == 0) {
            this.isShowreq = false;
            this.pesan = "... Data Not Found ...";
          } else {
            this.isShowreq = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    fetchrequestmaterial() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "request_material/getv_daftar_nomor_request_material_list";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.reqmaterial = resp.data.data;
          this.reqmaterial.forEach((item) => {
            item.value = item.nomor_request;
            item.label = item.nomor_request;
          });
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    searchdata() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const params = this.getRequestParams(this.pagestat);
      //const params = this.getRequestParams(this.page);
      const urlAPIget =
        this.$apiurl +
        "request_material/getalldata_request_material_vs_qty_out_plus_stok_wh?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize +
        "&nomor_request=" +
        this.valuereqmaterial;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isShowreq = false;
            this.pesan = "... Data Not Found ...";
          } else {
            this.isShowreq = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    /* fetchreqmaterial() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "request_material/getalldatarequest_material?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.list_paged = resp.data.data;
          this.count = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    }, */
    /* getproduk() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "produk_h/getproduk_hbynomor_produk?nomor_produk=" +
        this.isidata.noproduk;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Product Number not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.namaproduk = resp.data.data.nama_produk;
            this.isidata.noproduk = resp.data.data.nomor_produk;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    }, */
    /* getbuyer() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "buyer/getbuyerbykode?kode_buyer=" +
        this.isidata.buyerno;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Buyer not found",
              showConfirmButton: false
            });
            this.loading = false;
          } else {
            this.isidata.namabuyer = resp.data.data.name;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    }, */
    /*  getorder() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "buyer_order/getbuyer_orderbyorder_number?order_number=" +
        this.isidata.orderno;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Order Number not found",
              showConfirmButton: false
            });
            this.loading = false;
          } else {
            this.isidata.tglorder = resp.data.data.tanggal_order;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    }, */

    // async saveData() {
    saveData: function(classdata) {
      var qtyout = document.getElementById("inpd-" + classdata.id).value;
      if (qtyout > classdata.stok_wh) {
        alert("Sorry, not allowed to enter more than stock");
      } else {
        this.loading = true;
        var noout = this.isidata.outgoingno;
        var catat = this.isidata.catatan;
        var keluartgl = this.isidata.tglkeluar;
        var noorder = classdata.nomor_order;

        if (noout == "") {
          swal({
            icon: "warning",
            title: "Warning",
            text: "Outgoing Number can not be empty",
            showConfirmButton: false
          });
          this.loading = false;
        } else if (catat == "") {
          swal({
            icon: "warning",
            title: "Warning",
            text: "Notes can not be empty",
            showConfirmButton: false
          });
          this.loading = false;
        } else if (keluartgl == "") {
          swal({
            icon: "warning",
            title: "Warning",
            text: "Out Date can not be empty",
            showConfirmButton: false
          });
          this.loading = false;
        } else if (noorder == "") {
          swal({
            icon: "warning",
            title: "Warning",
            text: "Order Number can not be empty",
            showConfirmButton: false
          });
          this.loading = false;
        } else {
          // const tokenlogin = localStorage.getItem("token");
          const kodeuser = sessionStorage.getItem("kodeuser");
          const paramdata = {
            outgoing_number: this.isidata.outgoingno,
            order_number: classdata.nomor_order,
            notes: this.isidata.catatan,
            date_out: this.isidata.tglkeluar,
            kode_user: kodeuser,
            nama_buyer: "",
            nama_produk: "",
            jenis_out: this.isidata.jenisout,
            nomor_kontrak: this.isidata.nomorkontrak,
            request_number: classdata.nomor_request,
            nomor_kepabeanan: this.isidata.nomorkepabeanan,
            tanggal_kepabeanan: this.isidata.tglkepabeanan,
            jenis_kepabeanan: this.isidata.bctype
          };

          const paramdatad = {
            outgoing_number: this.isidata.outgoingno,
            kode_barang: classdata.kode_barang,
            qty: qtyout,
            kode_user: kodeuser
          };

          // console.log(paramdata);

          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIsavedata = this.$apiurl + "outgoing_h/saveoutgoing_h";

          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .post(urlAPIsavedata, paramdata, { headers })
            .then(() => {
              const urlAPIsavedatad =
                this.$apiurl + "outgoing_d/saveoutgoing_d";

              axios
                .post(urlAPIsavedatad, paramdatad, { headers })
                .then((respo) => {
                  console.log(paramdatad);
                  if (respo.data.status == true) {
                    swal({
                      icon: "success",
                      title: "Success",
                      text: "Save data successfully",
                      showConfirmButton: false
                    });
                    this.loading = false;
                    // this.$router.push({ name: "outgoingheader" });
                  } else {
                    swal({
                      icon: "warning",
                      title: "Warning",
                      text: "Save data failed",
                      showConfirmButton: false
                    });
                    this.loading = false;
                  }
                })
                .catch((err) => {
                  swal({
                    icon: "warning",
                    title: "Warning",
                    text: err.response.data,
                    showConfirmButton: false
                  });
                  console.log(err);
                  this.loading = false;
                });
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
                showConfirmButton: false
              });
              console.log(err);
              this.loading = false;
            });
        }
      }
    }
  }
};
</script>

<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
